import { useStaticQuery, graphql } from "gatsby";
import resolveResource from "@dataResolvers/resolveResource";

const query = graphql`
  query AllResourcesQuery {
    craft {
      entries(section: "resources") {
        ... on Craft_resources_articleEssay_Entry {
          uid
          title
          uid
          url
          external: boolean0
          type: typeHandle
          collection {
            uid
            title
            slug
          }
          audiences {
            uid
            title
            slug
          }
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          categoryLabel: label0
        }
        ... on Craft_resources_video_Entry {
          uid
          title
          uid
          url
          external: boolean0
          type: typeHandle
          collection {
            uid
            title
            slug
          }
          audiences {
            uid
            title
            slug
          }
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          categoryLabel: label0
        }
        ... on Craft_resources_book_Entry {
          uid
          title
          uid
          url
          external: boolean0
          type: typeHandle
          collection {
            uid
            title
            slug
          }
          audiences {
            uid
            title
            slug
          }
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          categoryLabel: label0
        }
        ... on Craft_resources_tool_Entry {
          uid
          title
          uid
          url
          external: boolean0
          type: typeHandle
          collection {
            uid
            title
            slug
          }
          audiences {
            uid
            title
            slug
          }
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          categoryLabel: label0
        }
        ... on Craft_resources_academicArticle_Entry {
          uid
          title
          uid
          url
          external: boolean0
          type: typeHandle
          collection {
            uid
            title
            slug
          }
          audiences {
            uid
            title
            slug
          }
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          categoryLabel: label0
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  return entries.map(resolveResource);
};

const useArticlesData = () => {
  const { craft } = useStaticQuery(query);
  return dataResolver(craft);
};

export default useArticlesData;
